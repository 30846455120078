import { combineReducers } from "redux";
import { quoteReducer } from "./quoteReducer";
import { authReducer } from "./authReducer";
import { userReducer } from "./userReducer";

export const rootReducer = combineReducers({
  quoteReducer,
  authReducer,
  userReducer
});
