import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { SuccessCOmponent } from "../modules/Cotizador/components/SuccessCOmponent";
import CotizadorPage from "../modules/Cotizador/CotizadorPage";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../actions/user";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase/firebase";
import { login } from "../actions/auth";

export default function AppRouter() {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const { token } = useSelector((state) => state.authReducer);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user?.uid) {
        dispatch(
          login(
            user.uid,
            user.displayName ? user.displayName : "",
            user.email,
            true,
            user.accessToken
          )
        );
        // setisLogged(true);
      }
    });

    // Cleanup function to unsubscribe on unmount
    return () => unsubscribe();
  }, [dispatch]);

  useEffect(() => {
    if(token) getUser(token, dispatch);
  }, [token])

  return (
    <Router>
      <Routes>
        <Route path="/" element={<CotizadorPage />} />
        <Route path="/success" element={<SuccessCOmponent />} />
        <Route
          path="*"
          element={
            <div>
              <h1>Esta pagina no existe</h1>
            </div>
          }
        />
      </Routes>
    </Router>
  );
}
