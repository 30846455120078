import React, { useState } from "react";
import "../styles/components/baseDropDownItems.css";

export default function BaseDropDownItemsComponent({
  items,
  onItemClick,
  selectedItem,
  isYear,
  setSelectedOptions,
  dataSelectedOptions,
  type,
  isMobile,
}) {
  const handleItemClick = (item) => {
    onItemClick(item.name, item.id);
    if (type === "year") {
      setSelectedOptions({
        year: {
          name: item.name,
          id: item.id,
        },
        brand: null,
        model: null,
        version: null,
        kms: null,
      });
    } else if (type === "brand") {
      setSelectedOptions({
        year: {
          name: dataSelectedOptions.year.name,
          id: dataSelectedOptions.year.id,
        },
        brand: {
          name: item.name,
          id: item.id,
        },
        model: null,
        version: null,
        kms: null,
      });
    } else if (type === "model") {
      setSelectedOptions({
        year: {
          name: dataSelectedOptions?.year?.name,
          id: dataSelectedOptions?.year?.id,
        },
        brand: {
          name: dataSelectedOptions?.brand?.name,
          id: dataSelectedOptions?.brand?.id,
        },
        model: {
          name: item.name,
          id: item.id,
        },
        version: null,
        kms: null,
      });
    } else if (type === "version") {
      setSelectedOptions({
        year: {
          name: dataSelectedOptions?.year?.name,
          id: dataSelectedOptions?.year?.id,
        },
        brand: {
          name: dataSelectedOptions?.brand?.name,
          id: dataSelectedOptions?.brand?.id,
        },
        model: {
          name: dataSelectedOptions?.model?.name,
          id: dataSelectedOptions?.model?.id,
        },
        version: {
          name: item.name,
          id: item.id,
        },
        kms: null,
      });
    }
  };

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      {isMobile ? (
        <div
          className="dropdown-item-container"
          style={{
            height: isYear ? "100%" : "310px",
            marginTop: isYear ? "10px" : "",
          }}
        >
          {items?.map((item, index) => (
            <div key={index}>
              <div
                className={`text-option-mobile`}
                onClick={() => handleItemClick(item)}
              >
                {item.name}
              </div>
              <div className="dropdown-item-divider" />
            </div>
          ))}
        </div>
      ) : (
        <div
          className="dropdown-item-container"
          style={{
            height: isYear ? "100%" : "310px",
            marginTop: isYear ? "10px" : "",
          }}
        >
          {items?.map((item, index) => (
            <div key={index} className="container-text-animated">
              {item?.name?.length > 22 ? (
                <div
                  id="animated-text-strip"
                  className="animated-text-container"
                  onClick={() => handleItemClick(item)}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  {hoveredIndex !== index ? (
                    <div className="text-animated">{item.name}</div>
                  ) : (
                    <>
                      <div className="text-animated">
                        &nbsp;{item.name}&nbsp;&nbsp; &nbsp; &nbsp;
                      </div>
                      <div className="text-animated">
                        &nbsp;{item.name}&nbsp;&nbsp; &nbsp; &nbsp;
                      </div>
                      <div className="text-animated">
                        &nbsp; {item.name}&nbsp;&nbsp; &nbsp; &nbsp;
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div
                  className="text-animated"
                  onClick={() => handleItemClick(item)}
                >
                  {item.name}
                </div>
              )}
              <div className="dropdown-item-divider" />
            </div>
          ))}
        </div>
      )}
    </>
  );
}
