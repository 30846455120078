import React, { useState } from "react";
import BasePurpleButtonComponent from "./BasePurpleButtonComponent";
import { colors } from "../styles/colors";
import BaseRadioButtonGroup from "./BaseRadioButtonGroup";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { TitleHomeWithIcon } from "./TitleHomeWithIcon";

export default function ManualQuoteModalComponent() {
  let isPageWide = useMediaQuery("(min-width: 770px)");
  const [number, setNumber] = useState("");
  const handleInputChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, ""); // Eliminar caracteres no numéricos
    const formattedNumber = Number(inputValue).toLocaleString(); // Formatear el número con separadores de miles
    setNumber(formattedNumber);
  };
  return (
    <div className={isPageWide ? "mt-4" : ""}>
      <TitleHomeWithIcon
        title={"Ingresa los datos manualmente"}
        fontSize={"20px"}
        colorText={colors.purple.purple}
        colorIcon={colors.purple.purple}
      />
      <div className={`card-contact-data ${isPageWide ? "mt-4" : ""}`}>
        <div className="h-100 d-flex flex-column justify-content-between">
          <div className="mb-1">
            <label className="input-labels">Año</label>
            <input
              type="number"
              className="base-input"
              placeholder="Ingresar un año"
            />
          </div>
          <div className="mb-1">
            <label className="input-labels">Marca</label>
            <input
              type="text"
              className="base-input"
              placeholder="Ingresar una marca"
            />
          </div>
          <div className="mb-1">
            <label className="input-labels">Modelo</label>
            <input
              type="text"
              className="base-input"
              placeholder="Ingresar un modelo"
            />
          </div>
          <div className="mb-1">
            <label className="input-labels">Versión</label>
            <input
              type="text"
              className="base-input"
              placeholder="Ingresar una version"
            />
          </div>
          <div className="mb-1">
            <label className="input-labels">Kilómetros</label>
            <input
              type="text"
              className="base-input"
              placeholder="Ej: 24.000"
              value={number}
              onChange={handleInputChange}
            />
          </div>
          <div className="d-flex mt-2 mb-4">
            <BaseRadioButtonGroup />
          </div>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <BasePurpleButtonComponent
              buttonText={"Cotizar"}
              onClick={() => {}}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
