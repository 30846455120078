import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import LogoAutaHeader from "../images/logo-auta-header.png";
import "../styles/components/header.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HeaderSessionButtonComponent from "./HeaderSessionButtonComponent";
import IconUser from "../images/icon-user.svg";
import IconArrowDown from "../images/icon-arrow-down.svg";
import IconLogout from "../images/icon-logout.svg";
import IconUserDark from "../images/icon-user-dark.svg";
import ModalAuthComponent from "../modules/Auth/components/ModalAuthComponent";
import { startLogout } from "../actions/auth";

export const HeaderComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isAuthenticated, name } = useSelector((state) => state.authReducer);
  const [showDropDown, setShowDropDown] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [getInWithEmail, setGetInWithEmail] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const toggleLoginModal = () => {
    setIsModalOpen(!isModalOpen);
    setGetInWithEmail(false);
    setShowLogin(false);
  };

  /* const handleToggle = () => {
    setExpanded(!expanded);
  }; */
  const logOutAndRedirect = () => {
    startLogout(dispatch);
    window.location.reload();
  };

  const showDropDownLogout = () => {
    setShowDropDown(!showDropDown);
  };

  return (
    <>
      <Container
        style={{
          position: "sticky",
          top: "0",
          zIndex: "4",
        }}
      >
        <div className="container-all">
          <Navbar expand="md" className="header">
            <Navbar.Brand
              onClick={() => window.location.reload()}
              style={{ cursor: "pointer", padding: "0px !important" }}
            >
              <img
                src={LogoAutaHeader}
                alt="logo-auta"
                className="logo-auta-header ms-3 ms-md-4"
              />
            </Navbar.Brand>
            <div className="justify-content-end  mb-1" style={{ marginTop: showDropDown ? "-20px" : "0px" }}>
              {/* <div className="d-flex align-items-center justify-content-center d-md-none">
                <div className="divider-header-menu"></div>
              </div>
              <div className="d-flex align-items-center justify-content-center d-md-none">
                <div className="divider-header-menu"></div>
              </div> */}
              <Nav.Link className="me-4 d-flex justify-content-center ">
                {isAuthenticated ? (
                  <div
                    onClick={showDropDownLogout}
                    className={`d-flex flex-column align-items-center ${
                      showDropDown ? "dropdown-logout" : ""
                    }`}
                    style={{ marginBottom: showDropDown ? "-74px" : "" }}
                  >
                    <div className="dropdown-user d-flex align-items-center justify-content-between">
                      <img
                        src={IconUser}
                        alt="icon-user"
                        className="icon-user"
                      />
                      <p className="text-user-name ms-1 me-1">{name}</p>
                      <img src={IconArrowDown} alt="arrow-down" />
                    </div>
                    {showDropDown ? (
                      <>
                        <div
                          className="d-flex align-items-center justify-content-center mt-3"
                          onClick={logOutAndRedirect}
                        >
                          <img src={IconLogout} alt="icon-logout" />
                          <p className="text-logout">Logout</p>
                        </div>
                      </>
                    ) : null}
                  </div>
                ) : (
                  <HeaderSessionButtonComponent
                    buttonText={"Sign up / Login"}
                    onClick={toggleLoginModal}
                  />
                )}
              </Nav.Link>
              {/* <div
                className="d-flex align-items-center justify-content-center d-md-none"
                style={{ height: "10px" }}
              ></div> */}
            </div>
          </Navbar>
        </div>
      </Container>
      <ModalAuthComponent
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        toggleLoginModal={toggleLoginModal}
        getInWithEmail={getInWithEmail}
        setGetInWithEmail={setGetInWithEmail}
        showLogin={showLogin}
        setShowLogin={setShowLogin}
      />
    </>
  );
};
