import { types } from "../types/types";

export const getQuote = (price, deal_id, car) => ({
  type: types.getQuote,
  payload: {
    price,
    deal_id,
    car,
  },
});

export const setQuote = (data) => ({
  type: types.setQuote,
  payload: data,
});
