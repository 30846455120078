import React from "react";
import { colors } from "../../../styles/colors";
import "../../../styles/components/baseWhiteButton.css";

export const ModalStopperComponent = ({
  showModalStopper,
  setShowModalStopper,
  setSelectedOptions,
  setNumber,
  setIsGnc,
  isMobile,
  setShowDropdownKms,
  handleButtonClick,
}) => {
  if (!showModalStopper) return null;

  const handleClose = () => {
    setShowModalStopper(false);
  };

  const handleCloseError = () => {
    setSelectedOptions({
      year: null,
      brand: null,
      model: null,
      version: null,
      kms: null,
    });
    setNumber("");
    setIsGnc("");
    setShowDropdownKms(false);
    if (isMobile) {
      handleButtonClick(1);
    }
    setShowModalStopper(false);
  };

  const handleModalClick = (e) => {
    if (e.target.closest(".modal-success")) {
      return;
    }
    handleCloseError();
    handleClose();
  };

  return (
    <div className="modal-overlay" onClick={handleModalClick}>
      <div
        className="modal-success background-modal-stopper"
        style={{ backgroundColor: colors.purple.purple }}
      >
        <div
          className="d-flex align-items-center justify-content-between flex-column"
          style={{ height: "100%" }}
        >
          <div className="d-flex align-items-center justify-content-center flex-column mt-4">
            <p className="text-title-modal-stopper">¡Ups!</p>
            <p className="text-description-modal-stopper mt-2">
              Lamentablemente no podemos hacer una oferta de <br /> compra por
              tu auto. Agradecemos tu interés y quedamos <br /> a disposición
              para cualquier consulta futura.
            </p>
            <p className="text-description-modal-stopper-mobile mt-2">
              Lamentablemente no podemos hacer una oferta de compra por tu auto.
              Agradecemos tu interés y quedamos a disposición para cualquier
              consulta futura.
            </p>
          </div>
          <div>
            <p className="text-footer-modal-stopper mb-4">
              ¡Que tengas un lindo dia!
            </p>
            <div className="d-flex align-items-center justify-content-center mb-4">
              <button className="white-button" onClick={handleCloseError}>
                Volver al inicio
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
