import React, { useState } from "react";
import "../style/dropDown.css";

export default function DropDownComponent({
  children,
  textButton,
  isDisabled,
  onItemClick,
  isMobile,
  selectedOptions,
}) {
  const [isOpen, setIsOpen] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item, id) => {
    setSelectedItem(item);
    if (!isMobile) {
      setIsOpen(false); // Cierra el menú cuando se selecciona un elemento solo cuando no es mobile
    }
    onItemClick(item, id);
  };

  return (
    <div className="mobile-dropdown-container">
      <button
        onClick={toggleMenu}
        className={`mobile-dropdown-button ${
          selectedItem ? "mobile-selected-value" : ""
        }`}
        disabled={isDisabled}
      >
        {selectedOptions || textButton}
      </button>
      {isOpen && (
        <div className={`mobile-menu ${isOpen ? "open" : ""}`}>
          {React.Children.map(children, (child) =>
            React.cloneElement(child, {
              onItemClick: handleItemClick, // Pasa la función handleItemClick como prop
              selectedItem,
            })
          )}
        </div>
      )}
    </div>
  );
}
