import React from "react";
import { Modal } from "react-bootstrap";
import "../style/baseModalAuth.css";
import BackButton from "../images/back-button.svg";
import CloseButton from "../images/close-button.svg";

const BaseModalAuthComponent = ({
  show,
  onClose,
  children,
  getInWithEmail,
  setGetInWithEmail,
  showLogin,
  setShowLogin,
}) => {
  const handleEvent = () => {
    if (getInWithEmail) {
      setGetInWithEmail(false);
    } else if (showLogin) {
      setShowLogin(false);
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Body className="modal-base-auth">
        <div
          className={`d-flex align-items-center justify-content-${
            getInWithEmail || showLogin ? "between" : "end"
          }`}
        >
          {getInWithEmail || showLogin ? (
            <img
              src={BackButton}
              alt="back-button"
              className="icon-modal-auth"
              onClick={handleEvent}
            />
          ) : null}
          <img
            src={CloseButton}
            alt="close-button"
            className="icon-modal-auth"
            onClick={onClose}
          />
        </div>
        <div className="d-flex align-items-center justify-content-center flex-column">
          {children}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BaseModalAuthComponent;
