import React from "react";
import CardBaseAuthComponent from "./CardBaseAuthComponent";
import {
  startGoogleLogin,
  startLoginEmailPassword,
} from "../../../actions/auth";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useState } from "react";

const LoginComponent = ({
  getInWithEmail,
  setGetInWithEmail,
  setShowLogin,
  setCurrentView,
  isSale,
  isSaleMobile,
  handleButtonClick,
  setIsModalOpen,
}) => {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const [errorAuth, setErrorAuth] = useState("");

  const loginUser = (data) => {
    dispatch(
      startLoginEmailPassword(
        data.email,
        data.password,
        setErrorAuth,
        setCurrentView,
        isSale,
        isSaleMobile,
        handleButtonClick,
        setIsModalOpen
      )
    );
  };

  return (
    <CardBaseAuthComponent
      text={
        "Completá los campos"
      }
    >
      <div className="d-flex align-items-center justify-content-center flex-column">
          <>
            <form onSubmit={handleSubmit(loginUser)} style={{ width: "100%" }}>
              <div className="d-flex align-items-center justify-content-center flex-column">
                <div
                  className="d-flex align-items-start justify-content-start flex-column mt-3 mb-3"
                  style={{ width: "100%" }}
                >
                  <label className="label-input">Email</label>
                  <input
                    placeholder="Ingrese su email"
                    className="base-input"
                    type="email"
                    {...register("email")}
                  />
                </div>
                <div
                  className="d-flex align-items-start justify-content-start flex-column"
                  style={{ width: "100%" }}
                >
                  <label className="label-input">Contraseña</label>
                  <input
                    placeholder="Ingrese su contraseña"
                    className="base-input"
                    type="password"
                    {...register("password")}
                  />
                </div>
                {/* <p className="option-login-email mt-3">Olvidé mi contraseña</p> */}
                <button className="btn-auth mt-4 mb-2">Ingresar</button>
              </div>
            </form>
          </>
        <p>{errorAuth}</p>
      </div>
    </CardBaseAuthComponent>
  );
};

export default LoginComponent;
