import { createStore, compose, applyMiddleware } from "redux";
import { thunk } from "redux-thunk"; // Importa thunk directamente
import { rootReducer } from "../reducer/rootReducer";

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)) // Usa thunk directamente
);
