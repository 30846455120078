import React from "react";

export const FooterCotizadorComponent = () => {
  return (
    <div className="d-flex w-100 justify-content-center container-footer-cotizacion">
      <a
        href="https://wa.me/+5491123707738 "
        target="_blank"
        rel="noopener noreferrer"
        className="link-contact-agent"
      >
        <p className="text-footer">
          ¿No encontrás tu auto?
          <br />
          <strong>Consultá con nosotros y recibí tu oferta.</strong>
        </p>
      </a>
    </div>
  );
};
