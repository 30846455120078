import React, { useState } from "react";
import BaseDropDownComponent from "../../../../components/BaseDropDownComponent";
import BaseDropDownItemsComponent from "../../../../components/BaseDropDownItemsComponent";
import BasePurpleButtonComponent from "../../../../components/BasePurpleButtonComponent";
import BaseRadioButtonGroup from "../../../../components/BaseRadioButtonGroup";
import { Spinner } from "react-bootstrap";
import BaseSearchInputComponent from "../../../../components/BaseSearchInputComponent";
import { ModalStopperComponent } from "../ModalStopperComponent";

export const StepperCotizaComponent = ({
  dataYears,
  setSelectedOptions,
  selectedOptions,
  handleItemClick,
  selectedItem,
  dataBrandsSearch,
  setDataBrands,
  dataBrands,
  dataModelsSearch,
  setDataModels,
  dataModels,
  dataVersionsSearch,
  setDataVersions,
  dataVersions,
  number,
  handleInputChange,
  isGnc,
  setIsGnc,
  handleShowModalForm,
  isFormValid,
  handleOptionSelect,
  showDropdownKms,
  setShowDropdownKms,
  setNumber,
  handleStartClick
}) => {
  const [showDropdownYear, setShowDropdownYear] = useState(true);
  const [showDropdownBrand, setShowDropdownBrand] = useState(false);
  const [showDropdownModel, setShowDropdownModel] = useState(false);
  const [showDropdownVersion, setShowDropdownVersion] = useState(false);
  const [showModalStopper, setShowModalStopper] = useState(false);

  return (
    <>
      <BaseDropDownComponent
        type={"year"}
        textButton={"¿De qué año es tu auto?"}
        onItemClick={(selectedItem, id) => {
          handleOptionSelect("year", selectedItem, id);
          handleItemClick(selectedItem, id);
          setShowDropdownBrand(true);
          setShowDropdownYear(false);
        }}
        isDisabled={false} // Siempre habilitado en el primer paso
        setSelectedOptions={setSelectedOptions}
        selectedOptions={selectedOptions?.year?.name}
        dataSelectedOptions={selectedOptions}
        setShowDropdown={setShowDropdownYear}
        showDropdown={showDropdownYear}
        setShowDropdownBrand={setShowDropdownBrand}
        setShowDropdownModel={setShowDropdownModel}
        setShowDropdownVersion={setShowDropdownVersion}
        setShowDropdownKms={setShowDropdownKms}
        setShowDropdownYear={setShowDropdownYear}
      >
        {!dataYears ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "80%" }}
          >
            <Spinner
              animation="border"
              style={{
                color: " #7340d8",
              }}
            />
          </div>
        ) : (
          <BaseDropDownItemsComponent
            items={dataYears}
            onItemClick={handleItemClick}
            selectedItem={selectedItem}
            isYear={true}
          />
        )}
      </BaseDropDownComponent>
      <BaseDropDownComponent
        type={"brand"}
        textButton={"¿Qué marca es tu auto?"}
        onItemClick={(selectedItem, id) => {
          handleOptionSelect("brand", selectedItem, id);
          handleItemClick(selectedItem, id);
          setShowDropdownModel(true);
          setShowDropdownBrand(false);
        }}
        isDisabled={!selectedOptions.year} // Deshabilitado si no se ha seleccionado el año
        setSelectedOptions={setSelectedOptions}
        selectedOptions={selectedOptions?.brand?.name}
        dataSelectedOptions={selectedOptions}
        setShowDropdown={setShowDropdownBrand}
        showDropdown={showDropdownBrand}
        setShowDropdownBrand={setShowDropdownBrand}
        setShowDropdownModel={setShowDropdownModel}
        setShowDropdownVersion={setShowDropdownVersion}
        setShowDropdownKms={setShowDropdownKms}
        setShowDropdownYear={setShowDropdownYear}
      >
        <BaseSearchInputComponent
          searchText={"Buscá la marca"}
          dataSearch={dataBrandsSearch}
          setDataSearch={setDataBrands}
        />
        {!dataBrands ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "80%" }}
          >
            <Spinner
              animation="border"
              style={{
                color: " #7340d8",
              }}
            />
          </div>
        ) : (
          <BaseDropDownItemsComponent
            items={dataBrands}
            onItemClick={handleItemClick}
          />
        )}
      </BaseDropDownComponent>
      <BaseDropDownComponent
        type={"model"}
        textButton={"¿Qué modelo es tu auto?"}
        onItemClick={(selectedItem, id) => {
          handleOptionSelect("model", selectedItem, id);
          handleItemClick(selectedItem, id);
          setShowDropdownVersion(true);
          setShowDropdownModel(false);
        }}
        isDisabled={!selectedOptions.brand}
        setSelectedOptions={setSelectedOptions}
        selectedOptions={selectedOptions?.model?.name}
        dataSelectedOptions={selectedOptions}
        setShowDropdown={setShowDropdownModel}
        showDropdown={showDropdownModel}
        setShowDropdownBrand={setShowDropdownBrand}
        setShowDropdownModel={setShowDropdownModel}
        setShowDropdownVersion={setShowDropdownVersion}
        setShowDropdownKms={setShowDropdownKms}
        setShowDropdownYear={setShowDropdownYear}
      >
        <BaseSearchInputComponent
          searchText={"Buscá el modelo"}
          dataSearch={dataModelsSearch}
          setDataSearch={setDataModels}
        />
        {!dataModels ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "80%" }}
          >
            <Spinner
              animation="border"
              style={{
                color: " #7340d8",
              }}
            />
          </div>
        ) : (
          <BaseDropDownItemsComponent
            items={dataModels}
            onItemClick={handleItemClick}
          />
        )}
      </BaseDropDownComponent>
      <BaseDropDownComponent
        type={"version"}
        textButton={"¿Qué versión es tu auto?"}
        onItemClick={(selectedItem, id) => {
          handleOptionSelect("version", selectedItem, id);
          handleItemClick(selectedItem, id);
          setShowDropdownKms(true);
          setShowDropdownVersion(false);
        }}
        isDisabled={!selectedOptions.model} // Deshabilitado si no se ha seleccionado el modelo
        setSelectedOptions={setSelectedOptions}
        selectedOptions={selectedOptions?.version?.name}
        dataSelectedOptions={selectedOptions}
        setShowDropdown={setShowDropdownVersion}
        showDropdown={showDropdownVersion}
        setShowDropdownBrand={setShowDropdownBrand}
        setShowDropdownModel={setShowDropdownModel}
        setShowDropdownVersion={setShowDropdownVersion}
        setShowDropdownKms={setShowDropdownKms}
        setShowDropdownYear={setShowDropdownYear}
      >
        <BaseSearchInputComponent
          searchText={"Buscá la version"}
          dataSearch={dataVersionsSearch}
          setDataSearch={setDataVersions}
        />
        {!dataVersions ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "80%" }}
          >
            <Spinner
              animation="border"
              style={{
                color: " #7340d8",
              }}
            />
          </div>
        ) : (
          <BaseDropDownItemsComponent
            items={dataVersions}
            onItemClick={handleItemClick}
          />
        )}
      </BaseDropDownComponent>
      <BaseDropDownComponent
        textButton={"¿Cuántos kms tiene tu auto?"}
        onItemClick={(selectedItem) => {
          handleOptionSelect("kms", selectedItem);
          handleItemClick(selectedItem);
        }}
        isDisabled={!selectedOptions.version}
        setShowDropdown={setShowDropdownKms}
        showDropdown={showDropdownKms}
        setShowDropdownBrand={setShowDropdownBrand}
        setShowDropdownModel={setShowDropdownModel}
        setShowDropdownVersion={setShowDropdownVersion}
        setShowDropdownKms={setShowDropdownKms}
        setShowDropdownYear={setShowDropdownYear}
        type={"kms"}
      >
        <div
          style={{ height: "100%" }}
          className="d-flex flex-column align-items-center justify-content-between"
        >
          <div style={{ height: "100%" }}>
            <input
              type="text"
              className="base-input"
              style={{ marginTop: "10px", marginBottom: "10px" }}
              placeholder="Ej: 24.000"
              value={number}
              onChange={handleInputChange}
            />
            <div className="dropdown-item-divider" />
            <div
              style={{
                marginTop: "18px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <BaseRadioButtonGroup
                isGnc={isGnc}
                setIsGnc={setIsGnc}
                kms={number}
                version_id={selectedOptions?.version?.id}
                setShowModalStopper={setShowModalStopper}
              />
            </div>
          </div>
          <div
            style={{ height: "100%" }}
            className="d-flex align-items-center justify-content-end"
          >
            <BasePurpleButtonComponent
              buttonText={"Continuar"}
              onClick={handleStartClick}
              buttonDisabled={!isFormValid}
            />
          </div>
        </div>
      </BaseDropDownComponent>
      <ModalStopperComponent
        showModalStopper={showModalStopper}
        setShowModalStopper={setShowModalStopper}
        setSelectedOptions={setSelectedOptions}
        setNumber={setNumber}
        setIsGnc={setIsGnc}
        setShowDropdownKms={setShowDropdownKms}
      />
    </>
  );
};
