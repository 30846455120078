import React, { useState, useContext } from 'react';

const SpinnerContext = React.createContext();

export const SpinnerProvider = ({ children }) => {
  const [showSpinner, setShowSpinner] = useState(false);

  const showLoading = () => {
    setShowSpinner(true);
  };

  const hideLoading = () => {
    setShowSpinner(false);
  };

  return (
    <SpinnerContext.Provider value={{ showLoading, hideLoading, showSpinner }}>
      {children}
    </SpinnerContext.Provider>
  );
};

export const useSpinner = () => {
  return useContext(SpinnerContext);
};