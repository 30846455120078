import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import BasePurpleButtonComponent from "../../../../components/BasePurpleButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
/* import { enviarDatosAuto } from "../../../Home/services/fetchDataCar"; */
import { sendPhoneCode } from "../../../../actions/phoneVerification";
import { sendCotizacion } from "../../service/cotizar";
import { useNavigate } from "react-router-dom";
import { useSpinner } from "../../../../context/spinnerContext";
import SpinnerComponent from "../../../../components/SpinnerComponent";

export const ModalAuthenticated = ({
  handleSubmitWithUser,
  user,
  textError,
  dataCar,
  kms,
  isGnc,
  setCurrentView,
  setPriceQuote,
  setPhoneNumber
}) => {
  const { token, name, email } = useSelector((state) => state.authReducer);
  const { phone, verified_phone } = useSelector((state) => state.userReducer);
  const { register, handleSubmit, reset } = useForm();
  const [codArea, setcodArea] = useState(8);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showLoading, hideLoading, showSpinner } = useSpinner();

  useEffect(() => {
    if (verified_phone === null) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [verified_phone]);

  const sendSale = (data) => {
    setIsLoading(true);
    if (verified_phone) {
      sendCotizacion(
        dataCar?.brand?.name,
        dataCar?.brand?.id,
        dataCar?.model?.name,
        dataCar?.model?.id,
        dataCar?.version?.name,
        dataCar?.version?.id,
        dataCar?.year?.name,
        isGnc,
        kms,
        phone,
        name,
        email,
        setIsLoading,
        setCurrentView,
        setPriceQuote,
        navigate,
        dispatch
      );
    } else {
      const phone = `549${data.cod_area}${data.telefono}`;
      if (setPhoneNumber) setPhoneNumber(phone);
      sendPhoneCode(token, phone, dispatch);
      setTimeout(() => {
        setCurrentView("step2");
        setIsLoading(false);
      }, 2000);
    }
  };

  return (
    <div className="card-contact-data">
      <div className="card-contact-header">
        <p className="contact-header-title">
          {verified_phone ?
            "¡Estos son tus datos!"
            :
            "¡Completá tus datos!"
          }
        </p>
      </div>
      {showSpinner ? (
        <div style={{ marginTop: "50px" }}>
          <SpinnerComponent />
        </div> 
      ) : (
        <>
          <div style={{ padding: "12px", height: "364px", width: "355px" }}>
            <form
              className="d-flex flex-column justify-content-between"
              // onSubmit={handleSubmitWithUser}
              onSubmit={handleSubmit(sendSale)}
              style={{
                height: "100%",
              }}
            >
              <div>
                <div className="mb-2">
                  <label className="input-labels">Nombre</label>
                  <p>{name}</p>
                </div>
                <div className="mb-2">
                  <label className="input-labels">Email</label>
                  <p>{email}</p>
                </div>
                <div className="mb-2">
                  <label className="input-labels">Teléfono celular</label>
                  {verified_phone ?
                    <p>+{phone}</p>
                    :
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p className="phone-number">+549</p>
                      <input
                        type="text"
                        className="base-input"
                        placeholder="Ej: 11"
                        maxLength={3}
                        style={{ width: "65px", marginRight: "10px" }}
                        {...register("cod_area", {
                          required: true,
                        })}
                        onChange={(e) => {
                          setcodArea(e.target.value.length);
                        }}
                      />
                      <input
                        type="text"
                        className="base-input ms-1"
                        placeholder="Sin 15"
                        {...register("telefono", {
                          required: true,
                          maxLength: Math.abs(codArea - 10),
                          minLength: Math.abs(codArea - 10),
                        })}
                        maxLength={Math.abs(parseInt(codArea?.length) - 10)}
                        minLength={Math.abs(parseInt(codArea?.length) - 10)}
                      />
                    </div>
                  }
                  <p className="text-error">{textError}</p>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-between align-items-center">
                {!verified_phone &&
                  <p className="security-code-text">
                    Recibirás un código de seguridad.
                  </p>
                }
                {isLoading ? (
                  <Spinner
                    animation="border"
                    style={{
                      margin: "10px",
                      color: " #7340d8",
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <BasePurpleButtonComponent
                  buttonText={isLoading ? "Enviando..." : "Continuar"}
                  buttonDisabled={isLoading ? true : false}
                  type="submit"
                />
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};
