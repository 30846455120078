import axios from "axios";

export const years = [
  { id: 2024, name: 2024 },
  { id: 2023, name: 2023 },
  { id: 2022, name: 2022 },
  { id: 2021, name: 2021 },
  { id: 2020, name: 2020 },
  { id: 2019, name: 2019 },
  { id: 2018, name: 2018 },
  { id: 2017, name: 2017 },
  { id: 2016, name: 2016 },
  { id: 2015, name: 2015 },
  { id: 2014, name: 2014 },
  { id: 2013, name: 2013 },
  { id: 2012, name: 2012 },
  { id: 2011, name: 2011 },
  { id: 2010, name: 2010 },
  { id: 2009, name: 2009 },
  { id: 2008, name: 2008 },
  { id: 2007, name: 2007 },
  { id: 2006, name: 2006 },
  { id: 2005, name: 2005 },
  { id: 2004, name: 2004 },
  { id: 2003, name: 2003 },
  { id: 2002, name: 2002 },
  { id: 2001, name: 2001 },
  { id: 2000, name: 2000 },
];

export const getYears = async (setDataYears) => {
  axios
    .get(
      `https://pricing-auta-back-dot-admin-dev-355313.rj.r.appspot.com/quoter/get_years`
    )
    .then((resp) => {
      setDataYears(resp.data.years);
    })
    .catch((e) => {
      console.log(e);
    });
};


export const getBrands = async (año, setDataBrands, setDataBrandsSearch) => {
  if (año) {
    axios
      .get(
        `https://pricing-auta-back-dot-admin-prod-368214.rj.r.appspot.com/quoter/get_brands?year=${año}`
      )
      .then((resp) => {
        const sortedBrands = resp.data.brands.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setDataBrands(sortedBrands);
        setDataBrandsSearch(sortedBrands);
      })
      .catch((e) => {
        console.log(e);
      });
  }
};

export const getModels = async (
  brand,
  year,
  setDataModels,
  setDataModelsSearch
) => {
  if (brand) {
    axios
      .get(
        `https://pricing-auta-back-dot-admin-prod-368214.rj.r.appspot.com/quoter/get_models?year=${year}&brand=${brand}`
      )
      .then((resp) => {
        const sortedModels = resp.data.models.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setDataModels(sortedModels);
        setDataModelsSearch(sortedModels);
      })
      .catch((e) => {
        console.log(e);
      });
  }
};

export const getVersion = async (
  year,
  brand,
  model,
  setDataVersions,
  setDataVersionsSearch
) => {
  if (model) {
    axios
      .get(
        `https://pricing-auta-back-dot-admin-prod-368214.rj.r.appspot.com/quoter/get_versions?year=${year}&brand=${brand}&model=${model}`
      )
      .then((resp) => {
        setDataVersions(resp.data.versions);
        setDataVersionsSearch(resp.data.versions);
      })
      .catch((e) => {
        console.log(e);
      });
  }
};
