import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  updateProfile,
} from "firebase/auth";
import { auth, googleProvider } from "../firebase/firebase";
import { types } from "../types/types";
import { firebaseErrorMessages } from "../modules/Auth/utils/errors";
import { createUser, setUser } from "./user";

export const signInWithGoogleRedirect = () => {
  signInWithRedirect(auth, googleProvider);
};

export const startGoogleLogin = (
  setCurrentView,
  isSale,
  isSaleMobile,
  handleButtonClick,
  setIsModalOpen
) => {
  return async (dispatch) => {
    const { user } = await signInWithPopup(auth, googleProvider);
    dispatch(
      login(user.uid, user.displayName, user.email, true, user.accessToken)
    );
    createUser(user.displayName, user.email, "", user.accessToken, dispatch);
    if (isSaleMobile) {
      if(setCurrentView) setCurrentView("step1");
      if(handleButtonClick) handleButtonClick(6, true);
    } else if (isSale) {
      if(setCurrentView) setCurrentView("step3");
    }
    setIsModalOpen(false);
  };
};

export const startLoginEmailPassword = (
  email,
  password,
  setErrorAuth,
  setCurrentView,
  isSale,
  isSaleMobile,
  handleButtonClick,
  setIsModalOpen
) => {
  return async (dispatch) => {
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      dispatch(setUser({ verified_phone: undefined }));
      dispatch(
        login(user.uid, user.displayName, user.email, true, user.accessToken)
      );
      if (isSaleMobile) {
        if(setCurrentView) setCurrentView("step1");
        if(handleButtonClick) handleButtonClick(6, true);
      } else if (isSale) {
        if(setCurrentView) setCurrentView("step3");
      }
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error during sign in:", error);
      const errorMessage =
        firebaseErrorMessages[error.code] || firebaseErrorMessages["default"];
      setErrorAuth(errorMessage);
    }
  };
};

export const startRegisterWithEmailPasswordName = (
  email,
  password,
  name,
  setErrorAuth,
  setCurrentView,
  isSale,
  isSaleMobile,
  handleButtonClick,
  setIsModalOpen
) => {
  return async (dispatch) => {
    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await updateProfile(user, { displayName: name });
      dispatch(
        login(user.uid, user.displayName, user.email, true, user.accessToken)
      );
      createUser(user.displayName, user.email, "", user.accessToken, dispatch);
      if (isSaleMobile) {
        if(setCurrentView) setCurrentView("step1");
        if(handleButtonClick) handleButtonClick(6, true);
      } else if (isSale) {
        if(setCurrentView) setCurrentView("step3");
      }
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error during registration:", error);
      const errorMessage =
        firebaseErrorMessages[error.code] || firebaseErrorMessages["default"];
      setErrorAuth(errorMessage);
    }
  };
};

export const login = (uid, displayName, email, isAuthenticated, token) => ({
  type: types.login,
  payload: {
    uid,
    displayName,
    email,
    isAuthenticated,
    token,
  },
});

export const startLogout = (dispatch) => {
  signOut(auth);
  dispatch(logout(false));
};

export const logout = (isAuthenticated) => ({
  type: types.logout,
  payload: {
    isAuthenticated,
  },
});
