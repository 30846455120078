import React from "react";
import { Container } from "react-bootstrap";
import { MdKeyboardArrowDown } from "react-icons/md";

export const TitleHomeWithIcon = ({
  title,
  colorText,
  colorIcon,
  fontSize,
}) => {
  const styleText = {
    color: colorText,
    textAlign: "center",
    fontSize: fontSize,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  };

  const styleIcon = {
    marginTop: "-10px",
  };

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <p style={styleText}>{title}</p>
      <MdKeyboardArrowDown
        fontSize="36px"
        color={colorIcon}
        style={styleIcon}
      />
    </Container>
  );
};
