import React, { useState } from "react";
import CardBaseAuthComponent from "./CardBaseAuthComponent";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { startGoogleLogin, startRegisterWithEmailPasswordName } from "../../../actions/auth";
import GoogleIcon from "../images/google-icon.svg";

const RegisterComponent = ({
  setCurrentView,
  isSale,
  isSaleMobile,
  handleButtonClick,
  setIsModalOpen,
  setShowLogin
}) => {

  const handleShowLogin = () => [setShowLogin(true)];
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const [errorAuth, setErrorAuth] = useState("");

  // Función para detectar si el navegador es un navegador-en-app
  const isInAppBrowser = () => {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    return (
      ua.includes("Instagram") || ua.includes("FBAN") || ua.includes("FBAV")
    );
  };

  const googleLogin = () => {
    if (isInAppBrowser()) {
      const androidUrl = `intent://${window.location.host}${window.location.pathname}#Intent;scheme=https;package=com.android.chrome;end`;
      window.location.href = androidUrl;
    } else {
      dispatch(
        startGoogleLogin(
          setCurrentView,
          isSale,
          isSaleMobile,
          handleButtonClick,
          setIsModalOpen
        )
      );
    }
  };

  const registerUser = (data) => {
    dispatch(
      startRegisterWithEmailPasswordName(
        data.email,
        data.password,
        data.name,
        setErrorAuth,
        setCurrentView,
        isSale,
        isSaleMobile,
        handleButtonClick,
        setIsModalOpen
      )
    );
  };

  return (
    <CardBaseAuthComponent text={"Completá todos los campos"}>
      <form onSubmit={handleSubmit(registerUser)}>
        <div className="d-flex align-items-center justify-content-center flex-column">
          <div
            className="d-flex align-items-start justify-content-start flex-column mt-3 mb-3"
            style={{ width: "100%" }}
          >
            <label className="label-input">Nombre completo</label>
            <input
              placeholder="Ingrese su nombre completo"
              className="base-input"
              {...register("name")}
            />
          </div>
          <div
            className="d-flex align-items-start justify-content-start flex-column mb-3"
            style={{ width: "100%" }}
          >
            <label className="label-input">Email</label>
            <input
              placeholder="Ingrese su email"
              type="email"
              className="base-input"
              {...register("email")}
            />
          </div>
          <div
            className="d-flex align-items-start justify-content-start flex-column mb-3"
            style={{ width: "100%" }}
          >
            <label className="label-input">Contraseña</label>
            <input
              placeholder="Ingrese su contraseña"
              className="base-input"
              type="password"
              {...register("password")}
            />
          </div>
          <button className="btn-auth mt-3">Registrarse</button>
        </div>
      </form>
      <p>{errorAuth}</p>
      <div className="d-flex align-items-center justify-content-center flex-column mb-3 mt-3">
        <button
          className="button-google d-flex align-items-center justify-content-center"
          onClick={googleLogin}
        >
          <img src={GoogleIcon} alt="google-icon" className="google-icon" />
          Ingresar con Google
        </button>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <div className="division-line"></div>
      </div>
      <div
        className="d-flex align-items-center justify-content-center flex-column mt-3 mb-2"
        onClick={handleShowLogin}
        style={{ cursor: "pointer" }}
      >
        <label className="text-not-account">¿Ya tenés una cuenta en Auta?</label>
        <label className="text-new-account">Iniciar sesión</label>
      </div>
    </CardBaseAuthComponent>
  );
};

export default RegisterComponent;
