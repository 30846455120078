import { types } from "../types/types";

export const quoteReducer = (state = {}, action) => {
  switch (action.type) {
    case types.getQuote:
      return {
        price: action.payload.price,
        deal_id: action.payload.deal_id,
        car: action.payload.car,
      };
    case types.setQuote:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};
