import React from "react";
import "../style/cardBaseAuth.css";

const CardBaseAuthComponent = ({ children, text }) => {
  return (
    <>
      <div className="card-base-auth">
        <div className="header-card-base-auth">
          <p className="text-header-card-base-auth">{text}</p>
        </div>
        {children}
      </div>
    </>
  );
};

export default CardBaseAuthComponent;
