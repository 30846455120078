import axios from "axios";
import { setQuote } from "../../../actions/quote";

export const sendCotizacion = async (
  brand,
  brand_id,
  model,
  model_id,
  version,
  version_id,
  year,
  gnc,
  kms,
  telefono,
  nombre,
  email,
  setIsLoading,
  setCurrentView,
  setPriceQuote,
  navigate,
  dispatch
) => {
  const data = {
    brand,
    brand_id,
    model,
    model_id,
    version,
    version_id,
    year,
    gnc,
    kms,
    name: nombre,
    cel: telefono,
    email,
    source: "landing_auta",
  };

  axios
    .post(`https://infoauto-348420.rj.r.appspot.com/landing_auta`, data)
    .then((resp) => {
      setPriceQuote(resp.data.price);
      setCurrentView("step3");
      setIsLoading(false);
      dispatch(setQuote({
        price: resp?.data?.price,
        deal_id: resp?.data?.deal_id,
        car: {
          brand: brand,
          model: model,
          version: version,
          year: year,
          kms: kms,
        }
      }));
      navigate("/success")
    })
    .catch((e) => {
      console.log(e);
    });
};

export const activeServer = () => {
  axios
    .get(
      "https://pricing-auta-back-dot-admin-prod-368214.rj.r.appspot.com/quoter/check_app"
    )
    .then((resp) => {
      console.log(resp);
    })
    .catch((e) => {
      console.log(e);
    });
};
