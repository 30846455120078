import React, { useEffect } from "react";
import AppRouter from "./routes/AppRouter";
import { SpinnerProvider } from "./context/spinnerContext";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { activeUserServer } from "./actions/user";

function App() {

  useEffect(() => {
    activeUserServer();
  }, []);

  return (
    <>
      <SpinnerProvider>
        <Provider store={store}>
          <AppRouter />
        </Provider>
      </SpinnerProvider>
    </>
  );
}

export default App;
