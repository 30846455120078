export const firebaseErrorMessages = {
  // Errores de autenticación
  "auth/app-deleted": "La base de datos no está disponible.",
  "auth/app-not-authorized":
    "La aplicación no está autorizada para autenticarse con Firebase.",
  "auth/argument-error": "Error en uno de los argumentos proporcionados.",
  "auth/invalid-api-key":
    "La API key proporcionada no es válida.",
  "auth/invalid-user-token": "El token del usuario no es válido.",
  "auth/network-request-failed":
    "Error de red. Por favor, revise su conexión a Internet.",
  "auth/operation-not-allowed":
    "Operación no permitida. Por favor, contacte al soporte.",
  "auth/requires-recent-login":
    "Se requiere una autenticación reciente. Por favor, vuelva a iniciar sesión.",
  "auth/too-many-requests":
    "Demasiadas solicitudes en poco tiempo. Por favor, intente más tarde.",
  "auth/unauthorized-domain":
    "El dominio no está autorizado para realizar operaciones con Firebase.",
  "auth/user-disabled": "El usuario ha sido deshabilitado.",
  "auth/user-token-expired": "El token del usuario ha expirado.",
  "auth/web-storage-unsupported":
    "El almacenamiento web no es compatible en este navegador.",

  // Errores de gestión de usuarios
  "auth/email-already-in-use":
    "El correo electrónico ya está en uso. Por favor, use uno diferente.",
  "auth/invalid-email":
    "Correo electrónico inválido. Por favor, ingrese un correo electrónico válido.",
  "auth/user-not-found":
    "Usuario no encontrado. Por favor, verifique el correo electrónico.",
  "auth/wrong-password": "Contraseña incorrecta. Por favor, intente de nuevo.",
  "auth/weak-password":
    "La contraseña es muy débil. Por favor, use una contraseña más fuerte.",
  "auth/invalid-verification-code": "El código de verificación no es válido.",
  "auth/invalid-verification-id": "El ID de verificación no es válido.",
  "auth/credential-already-in-use":
    "La credencial ya está en uso por otra cuenta de usuario.",
  "auth/account-exists-with-different-credential":
    "La cuenta ya existe con una credencial diferente.",

  // Errores de proveedor de identidad
  "auth/popup-closed-by-user":
    "La ventana emergente se cerró antes de completar la operación.",
  "auth/cancelled-popup-request":
    "Se ha cancelado la solicitud de ventana emergente anterior.",

  // Otros errores
  "auth/invalid-credential": "La credencial no es válida o ha caducado.",
  "auth/invalid-api-key": "La clave de API proporcionada no es válida.",
  "auth/invalid-phone-number": "El número de teléfono no es válido.",
  "auth/missing-phone-number": "Falta el número de teléfono.",

  // Default error
  default: "Error desconocido. Por favor, intente de nuevo más tarde.",
};
