import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import BaseModalComponet from "../../../../components/BaseModalComponet";
import ManualQuoteModalComponent from "../../../../components/ManualQuoteModalComponent";
import ModalMinKmsComponent from "../../../../components/ModalMinKmsComponent";
import LogoAuta from "../../images/logo-purple-auta.png";
import { ModalContactForm } from "../ModalContactForm";
import { getBrands, getModels, getVersion } from "../../service/fetchDataCar";
import { TitleHomeWithIcon } from "../../../../components/TitleHomeWithIcon";
import "../../style/style.css";
import { FooterCotizadorComponent } from "../FooterCotizadorComponent";
import { StepperCotizaComponent } from "./StepperCotizaComponent";
import { colors } from "../../../../styles/colors";
import { ModalStopperComponent } from "../ModalStopperComponent";
import SessionAlertModalComponent from "../../../../components/SessionAlertModalComponent";
import { useSelector } from "react-redux";
import ContactDataFormSaleComponent from "../Form/ContactDataFormSaleComponent";
import ModalAuthComponent from "../../../Auth/components/ModalAuthComponent";

export const CotizadorDesktopComponent = ({ dataYears }) => {
  const { isAuthenticated } = useSelector((state) => state.authReducer);
  const [currentView, setCurrentView] = useState("step2");
  const [selectedItem, setSelectedItem] = useState(null);
  const [number, setNumber] = useState("");
  const [isGnc, setIsGnc] = useState(""); // Estado para el valor seleccionado
  const [isRenewManualModalOpen, setRenewManualModalOpen] = useState(false);
  const [showModalForm, setShowModalForm] = useState(false);
  const [showDropdownKms, setShowDropdownKms] = useState(false);
  const [showModalStopper, setShowModalStopper] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getInWithEmail, setGetInWithEmail] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const toggleLoginModal = () => {
    setIsModalOpen(!isModalOpen);
    /* onClose(); */
  };

  const handleInputChange = (e) => {
    setIsGnc("");
    const inputValue = e.target.value.replace(/\D/g, ""); // Elimina caracteres no numéricos
    if (inputValue === "") {
      setNumber(""); // Si inputValue está vacío, establece el estado como una cadena vacía
    } else {
      const formattedNumber = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      setNumber(formattedNumber);
    }
  };

  const toggleRenewModal = () => {
    setRenewManualModalOpen(!isRenewManualModalOpen);
  };

  const [selectedOptions, setSelectedOptions] = useState({
    year: null,
    brand: null,
    model: null,
    version: null,
    kms: null,
  });

  const [dataBrands, setDataBrands] = useState();
  const [dataBrandsSearch, setDataBrandsSearch] = useState();
  const [dataModels, setDataModels] = useState();
  const [dataModelsSearch, setDataModelsSearch] = useState();
  const [dataVersions, setDataVersions] = useState();
  const [dataVersionsSearch, setDataVersionsSearch] = useState();

  const [showModal, setShowModal] = useState(false);
  const [isContinuing, setIsContinuing] = useState(false);

  const handleShowModalForm = () => {
    setShowModalForm(true);
    setIsModalOpen(true);
  };

  useEffect(() => {
    getBrands(selectedOptions?.year?.name, setDataBrands, setDataBrandsSearch);
  }, [selectedOptions?.year]);

  useEffect(() => {
    getModels(
      selectedOptions?.brand?.name,
      selectedOptions?.year?.name,
      setDataModels,
      setDataModelsSearch
    );
  }, [selectedOptions?.brand]);

  useEffect(() => {
    getVersion(
      selectedOptions?.year?.name,
      selectedOptions?.brand?.name,
      selectedOptions?.model?.name,
      setDataVersions,
      setDataVersionsSearch
    );
  }, [selectedOptions?.model]);

  const handleOptionSelect = (option, value, id) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [option]: { id: id, name: value },
    }));
  };

  const handleItemClick = (selectedValue) => {
    setSelectedItem(selectedValue);
  };
  const isFormValid = isGnc !== "" && number !== "";

  const handleStartClick = () => {
    const formattedNumber = parseInt(number.replace(/\./g, ""), 10);
    if (currentView === "step2") {
      if (!isNaN(formattedNumber) && formattedNumber <= 1000) {
        if (!isContinuing) {
          setShowModal(true);
        } else {
          if (isAuthenticated) {
            setCurrentView("step3");
          } else {
            setIsModalOpen(true);
          }
        }
      } else {
        setShowModalForm(true);
        if (isAuthenticated) {
          setCurrentView("step3");
        } else {
          setIsModalOpen(true);
        }
      }
    }
  };

  return (
    <div className="container-cotizador">
      {showModalForm &&
        (isAuthenticated ?
          <ContactDataFormSaleComponent dataCar={selectedOptions} kms={number} isGnc={isGnc} />
          :
          <ModalAuthComponent
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
            toggleLoginModal={toggleLoginModal}
            getInWithEmail={getInWithEmail}
            setGetInWithEmail={setGetInWithEmail}
            showLogin={showLogin}
            setShowLogin={setShowLogin}
          />)
      }
      {showModalForm && isAuthenticated ? null :
        <>
          {currentView === "step2" ? (
            <div
              className="d-flex flex-column align-items-center justify-content-between"
              style={{ height: "100%" }}
            >
              <div style={{ width: "100%" }}>
                <TitleHomeWithIcon
                  title={"¡Cargá tu auto!"}
                  fontSize={"28px"}
                  colorText={colors.purple.purple}
                  colorIcon={colors.purple.purple}
                />
                <Container
                  className="d-flex align-items-start justify-content-around mt-2"
                  style={{ height: "450px", width: "1320px", }}
                >
                  <StepperCotizaComponent
                    dataYears={dataYears}
                    setSelectedOptions={setSelectedOptions}
                    selectedOptions={selectedOptions}
                    handleItemClick={handleItemClick}
                    selectedItem={selectedItem}
                    dataBrandsSearch={dataBrandsSearch}
                    setDataBrands={setDataBrands}
                    dataBrands={dataBrands}
                    dataModelsSearch={dataModelsSearch}
                    setDataModels={setDataModels}
                    dataModels={dataModels}
                    dataVersionsSearch={dataVersionsSearch}
                    setDataVersions={setDataVersions}
                    dataVersions={dataVersions}
                    number={number}
                    handleInputChange={handleInputChange}
                    isGnc={isGnc}
                    setIsGnc={setIsGnc}
                    handleShowModalForm={handleShowModalForm}
                    isFormValid={isFormValid}
                    handleOptionSelect={handleOptionSelect}
                    showDropdownKms={showDropdownKms}
                    setShowDropdownKms={setShowDropdownKms}
                    setNumber={setNumber}
                    handleStartClick={handleStartClick}
                  />
                </Container>
              </div>
              <FooterCotizadorComponent />
              <BaseModalComponet
                isOpen={isRenewManualModalOpen}
                onClose={toggleRenewModal}
                size={"xs"}
              >
                <ManualQuoteModalComponent />
              </BaseModalComponet>
            </div>
          ) : null}
          {/* <ModalContactForm
            show={showModalForm}
            setShow={setShowModalForm}
            selectedOptions={selectedOptions}
            kms={number}
            isGnc={isGnc}
            setSelectedOptions={setSelectedOptions}
            setNumber={setNumber}
            setIsGnc={setIsGnc}
            setShowDropdownKms={setShowDropdownKms}
            setShowModalStopper={setShowModalStopper}
          /> */}
          <ModalStopperComponent
            showModalStopper={showModalStopper}
            setShowModalStopper={setShowModalStopper}
            setSelectedOptions={setSelectedOptions}
            setNumber={setNumber}
            setIsGnc={setIsGnc}
            setShowDropdownKms={setShowDropdownKms}
          />
          {showModal && (
            <ModalMinKmsComponent
              show={showModal}
              kms={number}
              setShow={setShowModal}
              setIsContinuing={setIsContinuing}
              setCurrentView={setCurrentView}
              setIsModalOpen={setIsModalOpen}
              setShowModalForm={setShowModalForm}
            />
          )}
        </>
      }
    </div>
  );
};
